import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBottomSheet.figmaUrl.ios} codeUrl={checklists.componentBottomSheet.codeUrl.ios} checklists={checklists.componentBottomSheet.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Sheet is a container that slides from the bottom of the screen.
A Sheet can contain anything. It can be used to display a list of items, show more details about an item, or even display a form.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "alt": "Bottom Sheet Legion React Native",
            "height": 600,
            "resize-mode": "contain",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/react-native/react-native-bottom-sheet-1.png",
            "width": "auto"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button, Sheet, Text } from "@legion-crossplatform/ui"
import { useState } from "react"
import { ChevronDown, ChevronUp } from '@tamagui/lucide-icons'

export default function SheetDemo() {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState(0)

  return (
    <>
      <Button
        size="$6"
        icon={open ? ChevronDown : ChevronUp}
        circular
        onPress={() => setOpen((x) => !x)}
        variant="tertiary"
      />
      <Sheet
        modal
        animation="medium"
        open={open}
        onOpenChange={setOpen}
        snapPoints={[80]}
        position={position}
        onPositionChange={setPosition}
        dismissOnSnapToBottom
      >
        <Sheet.Overlay animation="lazy" enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} />
        <Sheet.Frame ai="center" jc="center">
          <Text>This is bottom sheet</Text>
          <Sheet.Handle />
          <Button
            variant="tertiary"
            size="$6"
            circular
            icon={ChevronDown}
            onPress={() => {
              setOpen(false)
            }}
          />
        </Sheet.Frame>
      </Sheet>
    </>
  )
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Anatomy`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Sheet } from '@legion-crossplatform/ui' 

export default () => (
  <Sheet>
    <Sheet.Overlay />
    <Sheet.Handle />
    <Sheet.Frame>{/* ...inner contents */}</Sheet.Frame>
  </Sheet>
)

`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`animationConfig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Animated.SpringAnimationConfig`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`true`}</inlineCode>{`. Customize the spring used, passed to react-native’s `}<inlineCode parentName="td">{`Animated.spring()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultOpen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uncontrolled open state on mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultPosition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uncontrolled default position on mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disableDrag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disables all touch events to drag the sheet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dismissOnOverlayPress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`true`}</inlineCode>{`. Controls tapping on the overlay to close`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dismissOnSnapToBottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds a snap point to the end of your snap points set to “0”, that when snapped to will set open to false (uncontrolled) and call `}<inlineCode parentName="td">{`onOpenChange`}</inlineCode>{` with false (controlled)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`forceRemoveScrollEnabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. By default, sheet uses `}<inlineCode parentName="td">{`react-remove-scroll`}</inlineCode>{` to prevent anything outside the sheet scrolling. This can cause some issues so you can override the behavior with this prop (either true or false)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renders sheet into the root of your app instead of inline`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`moveOnKeyboardChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. Native only flag that will amke the sheet move up when the mobile keyboard opens so the focused input remains visible`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onOpenChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(open: boolean) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called on change open, controlled or uncontrolled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onPositionChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(position: number) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called on change position, controlled or uncontrolled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to use as controlled component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`portalProps`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`YStack`}</inlineCode>{` property that can be passed to the Portal that sheet uses when in modal mode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controlled position, set to an index of `}<inlineCode parentName="td">{`snapPoints`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`snapPoints`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(number \\| string)[]`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`[80]`}</inlineCode>{`. Array of values representing different sizes for the sheet to snap to. Not used in ‘fit’ mode. See docs above for usage information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`snapPointsMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`percent`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`constant`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`fit`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`mixed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`percent`}</inlineCode>{`. Alters the behavior of the `}<inlineCode parentName="td">{`snapPoints`}</inlineCode>{` property. See docs above for usage information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unmountChildrenWhenHidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. Flag to enable unmounting the children after the exit animation has completed`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      